import React, { Component, useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
} from 'antd';
import Footer from '../../../components/layout-components/modal/Footer';
import { usePosition } from '../../../utils/usePosition';
import API from '../../../api';
import {
  addYardLocation,
  EditYardLocation,
} from '../../../actions/yardLocationAction';
import { useDispatch,useSelector } from 'react-redux';
import {useWindowDimensions } from '../../../utils/helper';
var moment = require('moment-timezone');
const { Option } = Select;

export const UserDetails = [
  {
    name: 'name',
    lable: 'Yard Name',
    col:12,
    rules: [
      {
        required: true,
        message: 'Please Enter yard name!',
        whitespace: true,
      },
    ],
  },
  // {
  //   name: 'phone_no',
  //   lable: 'Phone no.',
  //   col:12,
  //   rules: [
  //     {
  //       required: true,
  //       message: 'Please Enter your last name!',
  //       whitespace: true,
  //     },
  //   ],
  // },
  {
    name: 'address',
    lable: 'Yard Location',
    col:12,
    rules: [
      {
        min: 6,
        message: 'username must be 6 character!',
      },

      {
        required: true,
        message: 'Please Enter your address!',
        whitespace: true,
      },
    ],
  },
];


const RegistrationForm = (props) => {
  const dispatch=useDispatch()
  const loading = useSelector((state) => state?.yardLocationData?.loading);
  const { latitude, longitude } = usePosition();
  const [form] = Form.useForm();
  const [userDetails, setUserDetails] = useState(UserDetails);
  const [visible, setVisible] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const { height, width } = useWindowDimensions();
  useEffect(()=>{
    if(visible){
      form.setFieldsValue(props.data)
    }else{
      form.resetFields()
    }
  },[visible])
  const onFinish = (values) => {
    const callback=()=>{
        form.resetFields()
        setVisible(false)
    }
    if (!props?.data?.id) {
      dispatch(addYardLocation({
        ...values,
        coordinates: [latitude??0.00, longitude??0.00],
      },callback),);
    } else {
      dispatch(EditYardLocation({
        ...values,
        id: props.data.id,
        coordinates: [latitude??0.00, longitude??0.00],
      },callback));
    }

  };

  return (
    <Footer
      fullWidth={width>100?'50%':false}
      setVisible={(e) => setVisible(e)}
      visible={visible}
      Button={props.Button}
      title={props.title}
      custom={props.custom}
      className={props.className}
      submit={
        <Form.Item>
          <Button
          loading={loading}
            disabled={btnDisable}
            style={{background:'#38B6FF',color:'#fff'}}
            form={props?.data?.id ? `editLocation${props?.data?.id.toString()}` : 'newLocation'}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      }
    >
      <Form
        form={form}
        id={props?.data?.id ? `editLocation${props?.data?.id.toString()}` : 'newLocation'}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={24}>
          {userDetails && userDetails.map((form) => (
            <Col span={form.col}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name={form.name}
                  label={<span>{form.lable}</span>}
                  initialValue={form.initialValue ? form.initialValue : ''}
                  // initialValue={form.initialValue?form.initialValue:''}
                  rules={form.rules}
                >
                <Input />
                </Form.Item>
                </Col>
            ))}
          </Row>
      </Form>
    </Footer>
  );
};

export class Register extends Component {
  render() {
    return <RegistrationForm {...this.props} />;
  }
}

export default Register;
